import React, { useEffect, useState } from "react";
import s from "./page.module.css";
import pic from "../image/stock-photo2.jpg";
import wisecity from "./../image/Coming_Soon_Button.png";
import Page2 from "./pages/Page2";
import { authCheck } from "../redux/slices/regSlice";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import Partners from "./partners/Partners";
import { motion } from "framer-motion";
import Technology from "./technology/Technology";
import Futter from "./futter/Futter";
import GeneralCatalog from "./pages/catalog/GeneralCatalog";
import Log from "./regLog/log/Log";
import Reg from "./regLog/reg/Reg";
import { selectLog, selectReg } from "../redux/selectors";
import { goRegLog } from "../redux/slices/navigateSlice";
import { useNavigate } from "react-router-dom";
import PopUp from "./pop/PopUp";

const PageMaine = () => {
  const regShow = useSelector(selectReg);
  const logShow = useSelector(selectLog);
  const [stylePic, setstylePic] = useState({
    clipPath: "inset(200px 400px 200px 400px)",
  });

  const { ref, inView } = useInView({
    threshold: [0.4, 0.3],
  });
  const [pos, setPos] = useState([{}, {}]);
  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      setstylePic({ clipPath: "inset(0px 0px 0px 0px)" });
    } else {
      setstylePic({ clipPath: "inset(100px 400px 100px 400px)" });
    }
  }, [inView]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);
  useEffect(() => {
    if (logShow) {
      setTimeout(() => {
        setPos([{ right: 0, opacity: 1 }, {}]);
      }, 50);
    }
    if (regShow) {
      setTimeout(() => {
        setPos([{}, { right: 0 }]);
      }, 50);
    }
  }, [logShow, regShow]);

  const close = () => {
    dispatch(goRegLog({ registr: false, login: false }));
  };
  const textAnimation = {
    hidden: {
      x: -200,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.5, duration: 1 },
    }),
  };
  const textAnimation2 = {
    hidden: {
      x: 1000,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.5, duration: 1 },
    }),
  };
  return (
    <div className={s.site}>
      {logShow && <Log style={pos[0]} close={close} />}
      {regShow && <Reg style={pos[1]} close={close} />}
      <div style={logShow || regShow ? { opacity: 0.2 } : undefined}>
        
          <PopUp />
        
        <motion.div
          className={s.container}
          initial="hidden"
          whileInView="visible"
        >
          <img className={s.wisecityPic} src={wisecity} alt="" />
          <div className={s.headerTop}>
            <div className={s.headerContent}>
              <p className={s.headerTextLeft}>
                Ваш следующий проект начинается{" "}
              </p>
              <div className={s.headerTextBlock}>
                <motion.p
                  custom={2}
                  variants={textAnimation}
                  className={s.headerTextC}
                >
                  c
                </motion.p>
                <motion.p
                  custom={2}
                  variants={textAnimation}
                  className={s.headerTextP}
                >
                  Премекс
                </motion.p>
                <motion.p
                  custom={3}
                  variants={textAnimation2}
                  className={s.headerTexB}
                >
                  Бел
                </motion.p>
              </div>
            </div>

            <motion.h3
              custom={3}
              variants={textAnimation2}
              className={s.subtitle}
            >
              Умные технологии измерения будущего!
            </motion.h3>
          </div>
          <div
            className={s.order}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Связаться
          </div>
        </motion.div>
        <div ref={ref} className={s.promo}>
          <img style={stylePic} className={s.promoPic} src={pic} alt="" />
        </div>
        <Page2 />
        <div style={{ width: "100%", overflow: "hidden" }}>
          <GeneralCatalog />
        </div>

        <Technology />
        <Partners />
        <Futter />
      </div>

      {/*<TechnologyCard />*/}
    </div>
  );
};
export default PageMaine;
